<template>
  <div>
    <div v-if="this.selectedServiceProviderId === 'all'">
      <not-scoped
        scope="Service Provider"
        resource="Service provider profile"
      />
    </div>
    <div v-else>
      <el-container class="h-full">
        <el-container>
          <el-main>
            <div style="height: 50px;">
              <page-header
                style="max-width: 90%; padding-left: 0.7rem"
                :title="__('Service Provider Profile')"
              />
            </div>
            <el-tabs
              v-model="activeTab"
              style="margin-top: 10px; padding:1.5rem 1.5rem 2rem 0.7rem;"
            >
              <el-tab-pane :label="__('Theme Color')" name="themeColor">
                <div>
                  <el-row type="flex">
                    <el-col :span="12" :offset="0">
                      <el-form
                        ref="contentForm"
                        :rules="rules"
                        :model="contentForm"
                        label-position="top"
                        size="large"
                        v-loading="isLoading"
                      >
                        <el-row type="flex">
                          <el-col :span="18" :offset="0">
                            <el-form-item prop="theme_color">
                              <el-row type="flex">
                                <el-col :span="12">
                                  <span style="font-size: medium">{{
                                    __("Theme Color")
                                  }}</span>
                                </el-col>
                                <el-col :span="4">
                                  <el-color-picker
                                    v-model="contentForm.theme_color"
                                  ></el-color-picker>
                                </el-col>
                              </el-row>
                            </el-form-item>
                          </el-col>
                        </el-row>

                        <el-row type="flex">
                          <el-col :span="18" :offset="0">
                            <el-form-item prop="hover_color">
                              <el-row type="flex">
                                <el-col :span="12">
                                  <span style="font-size: medium">{{
                                    __("Theme Hover Color")
                                  }}</span>
                                </el-col>
                                <el-col :span="4">
                                  <el-color-picker
                                    v-model="contentForm.hover_color"
                                  ></el-color-picker>
                                </el-col>
                              </el-row>
                            </el-form-item>
                          </el-col>
                        </el-row>

                        <el-row type="flex">
                          <el-col :span="18" :offset="0">
                            <el-form-item prop="outline_color">
                              <el-row type="flex">
                                <el-col :span="12">
                                  <span style="font-size: medium">{{
                                    __("Table Row Outline Color")
                                  }}</span>
                                </el-col>
                                <el-col :span="4">
                                  <el-color-picker
                                    v-model="contentForm.outline_color"
                                  ></el-color-picker>
                                </el-col>
                              </el-row>
                            </el-form-item>
                          </el-col>
                        </el-row>

                        <el-row type="flex">
                          <el-col :span="18" :offset="0">
                            <el-form-item prop="row_hover_color">
                              <el-row type="flex">
                                <el-col :span="12">
                                  <span style="font-size: medium">{{
                                    __("Content Hover Color")
                                  }}</span>
                                </el-col>
                                <el-col :span="4">
                                  <el-color-picker
                                    v-model="contentForm.row_hover_color"
                                  ></el-color-picker>
                                </el-col>
                              </el-row>
                            </el-form-item>
                          </el-col>
                        </el-row>

                        <el-row type="flex" style="margin-top: 20px">
                          <el-col :span="12" :offset="0">
                            <el-form-item>
                              <el-button
                                @click="submitForm"
                                class="submitBtn"
                                :disabled="!canWrite()"
                                >{{ __("Update Profile") }}</el-button
                              >
                              <el-button
                                @click="resetColors"
                                class="submitBtn"
                                style="margin-top: 12px"
                                >{{ __("Reset") }}</el-button
                              >
                            </el-form-item></el-col
                          >
                        </el-row>
                      </el-form>
                    </el-col>
                    <el-col :span="12" :offset="0">
                      <el-row type="flex" style="margin-bottom: 20px">
                        <el-col :span="18" :offset="0">
                          <el-tabs
                            v-model="activeName"
                            class="tabs"
                            :style="themeColorStyle"
                          >
                            <el-tab-pane :label="__('List')" name="first">
                              <el-table
                                :data="tableData"
                                class="list-table"
                                :style="themeColorStyle"
                                width="100%"
                              >
                                <el-table-column
                                  prop="date"
                                  :label="__('Date')"
                                  width="180"
                                >
                                </el-table-column>
                                <el-table-column
                                  prop="name"
                                  :label="__('Name')"
                                  width="180"
                                >
                                </el-table-column>
                                <el-table-column
                                  prop="address"
                                  :label="__('Address')"
                                >
                                </el-table-column>
                              </el-table>
                            </el-tab-pane>
                            <el-tab-pane :label="__('Card')" name="second">
                              <el-row :gutter="12">
                                <el-col :span="8">
                                  <el-card
                                    :style="themeColorStyle"
                                    class="card"
                                    shadow="never"
                                  >
                                    card1
                                  </el-card>
                                </el-col>
                                <el-col :span="8">
                                  <el-card
                                    :style="themeColorStyle"
                                    class="card"
                                    shadow="never"
                                  >
                                    card2
                                  </el-card>
                                </el-col>
                                <el-col :span="8">
                                  <el-card
                                    :style="themeColorStyle"
                                    class="card"
                                    shadow="never"
                                  >
                                    card3
                                  </el-card>
                                </el-col>
                              </el-row>
                            </el-tab-pane>
                          </el-tabs>
                        </el-col>
                      </el-row>

                      <el-row type="flex" style="margin-bottom: 20px">
                        <el-col :span="18" :offset="0"> </el-col>
                      </el-row>

                      <el-row type="flex">
                        <el-col :span="18" :offset="0">
                          <el-button
                            type="primary"
                            class="someBtn"
                            :style="themeColorStyle"
                            >Button</el-button
                          >
                        </el-col>
                      </el-row>
                    </el-col></el-row
                  >
                </div>
              </el-tab-pane>

              <el-tab-pane :label="__('Email Settings')" name="emailProfile">
                <sp-email-settings></sp-email-settings>
              </el-tab-pane>

              <el-tab-pane :label="__('My Profile')" name="myProfile">
                <my-sp-profile> </my-sp-profile>
              </el-tab-pane>

              <el-tab-pane :label="__('Alert Settings')" name="alertSettings">
                <sp-alert-settings> </sp-alert-settings>
              </el-tab-pane>
            </el-tabs>
          </el-main>
        </el-container>
        <el-aside class="form-aside relative" :width="`${asideWidth}px`">
          <div class="aside-content">
            <div style="font-size: 14px;padding: 30px 15px;color: #A0A8B5">
              {{ asideText }}
            </div>
          </div>
          <slot name="form"></slot>
        </el-aside>
      </el-container>
    </div></div
></template>
<script>
import { mapActions, mapState } from "vuex";
import PageHeader from "@/components/PageHeader";
import _ from "lodash";
import NotScoped from "@/components/NotScoped";
import SpEmailSettings from "./sp-email-settings/index";
import MySpProfile from "./my-sp-profile/index";
import spAlertSettings from "./sp-alert-settings/index";

export default {
  name: "SpProfileManageIndex",
  components: {
    PageHeader,
    NotScoped,
    MySpProfile,
    SpEmailSettings,
    spAlertSettings
  },
  props: {
    formAction: {
      default: ""
    },
    asideText: {
      default: ""
    },
    asideButtonText: {
      default: "Create"
    },
    asideWidth: {
      type: Number,
      required: false,
      default: 274
    }
  },
  data() {
    return {
      activeTab: "themeColor",
      activeName: "first",
      isLoading: false,
      tableData: [
        {
          date: "2021-05-03",
          name: "Demo",
          address: "L5 530 Collins"
        },
        {
          date: "2021-05-02",
          name: "Demo",
          address: "L5 530 Collins"
        },
        {
          date: "2021-05-04",
          name: "Demo",
          address: "L5 530 Collins"
        }
      ],
      contentForm: {
        theme_color: "#244CDE",
        hover_color: "#247bb9",
        row_hover_color: "#EEF1FC",
        outline_color: "#9cd6fc"
      },
      rules: {
        theme_color: [
          {
            required: true,
            trigger: "blur",
            message: __("Theme color is required")
          }
        ],
        email: [
          {
            required: true,
            trigger: "blur",
            message: __("Email is required")
          }
        ],
        default_timezone: [
          {
            required: true,
            trigger: "blur",
            message: __("Timezone is required")
          }
        ]
      }
    };
  },

  computed: {
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId
    }),
    ...mapState("users", {
      contents: state => state.users
    }),
    ...mapState("theme", {
      colors: state => state.colors,
      platform_theme_color: state => state.colors.theme_color,
      platform_theme_hover_color: state => state.colors.hover_color,
      platform_theme_outline_color: state => state.colors.outline_color,
      platform_theme_row_hover_color: state => state.colors.row_hover_color
    }),
    themeColorStyle() {
      return {
        "--theme-color": this.contentForm.theme_color,
        "--theme-hover-color": this.contentForm.hover_color,
        "--theme-outline-color": this.contentForm.outline_color,
        "--theme-row-hover-color": this.contentForm.row_hover_color
      };
    },
    themeChanged() {
      return !(
        this.platform_theme_color === this.contentForm.theme_color &&
        this.platform_theme_hover_color === this.contentForm.hover_color &&
        this.platform_theme_outline_color === this.contentForm.outline_color &&
        this.platform_theme_row_hover_color === this.contentForm.row_hover_color
      );
    }
  },

  methods: {
    ...mapActions("users", {
      get2FA: "get2FA",
      getPasswordValidationSummary: "getPasswordValidationSummary"
    }),
    ...mapActions("serviceproviders", {
      storeSpSetting: "storeSpSetting"
    }),

    resetColors() {
      this.contentForm.theme_color = "#244CDE";
      this.contentForm.hover_color = "#247bb9";
      this.contentForm.row_hover_color = "#EEF1FC";
      this.contentForm.outline_color = "#9cd6fc";
    },
    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid && this.themeChanged) {
          const process = this.storeSpSetting;
          process(this.contentForm)
            .then(data => {
              console.log(data);
              this.$message({
                type: "success",
                message: __("Profile updated successfully.")
              });
            })
            .catch(err => {
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    }
  },
  mounted() {
    this.contentForm = _.cloneDeep(this.colors);
  },
  watch: {
    colors: function(val) {
      this.contentForm = _.cloneDeep(val);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/button.scss";
.h-full {
  height: 100%;
}

.aside-content {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.form-aside {
  background: white;
  border-left: #f5f5f8 1px solid;
}

.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
.someBtn {
  height: 44px;
  background-color: var(--theme-color);
  border-color: transparent;
  &:hover {
    background-color: var(--theme-hover-color);
    border-color: transparent;
  }
}

.tabs {
  font-size: 16px;
  cursor: pointer;
}
.tabs ::v-deep .el-tabs__item.is-active {
  color: var(--theme-color);
}

.tabs ::v-deep .el-tabs__item:hover {
  color: var(--theme-hover-color);
}

.tabs ::v-deep .el-tabs__active-bar {
  background-color: var(--theme-color);
}

.list-table {
  ::v-deep .el-table {
    padding: 10px;
    transition: width 1s ease-in-out;
    min-width: 194px;
  }
  ::v-deep .el-table__header th.is-leaf,
  .el-table td {
    border-bottom: 2px solid #f5f5f8;
  }
  ::v-deep .el-table__header-wrapper th > .cell {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    color: #a0a8b5;
  }
  ::v-deep .el-table__body tr > td:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  ::v-deep .el-table__body tr > td:last-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  ::v-deep .el-table__body tr:first-child:hover {
    outline: 2px solid var(--theme-outline-color);
  }
  ::v-deep .el-table__body tr:hover {
    outline: 2px solid var(--theme-outline-color);
  }

  ::v-deep .el-table__body tr:hover > td {
    background-color: var(--theme-row-hover-color);
  }

  ::v-deep .el-table__body tr.current-row > td {
    background-color: var(--theme-row-hover-color);
  }
}
.card {
  width: 120px;
  height: 120px;
  ::v-deep .el-card__body {
    margin-top: 25px;
    margin-left: 20px;
  }
  &:hover {
    background-color: var(--theme-row-hover-color);
    border-color: transparent;
  }
}
</style>
