var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-form',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"alertSettingsForm",attrs:{"id":"alertSettingsForm","model":_vm.alertSettings,"rules":_vm.rules,"label-position":"top","size":"large","hide-required-asterisk":""}},[_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":_vm.__('Email Addresses'),"prop":"email_receiver_list"}},[_c('el-input',{attrs:{"type":"textarea","placeholder":_vm.__('Enter Email Addresses'),"maxlength":"500","show-word-limit":""},model:{value:(_vm.alertSettings.email_receiver_list),callback:function ($$v) {_vm.$set(_vm.alertSettings, "email_receiver_list", $$v)},expression:"alertSettings.email_receiver_list"}}),_c('div',{staticStyle:{"font-size":"small"}},[_vm._v(" "+_vm._s(_vm.__("Alerts will be sent to the entered email addresses and repeat daily. You can enter multiple comma-separated email addresses."))+" ")])],1),_c('el-radio-group',{attrs:{"size":"medium"},on:{"change":_vm.handleAlertCandidateChange},model:{value:(_vm.alertCandidate),callback:function ($$v) {_vm.alertCandidate=$$v},expression:"alertCandidate"}},[_c('el-radio-button',{attrs:{"label":"IVA"}},[_vm._v("Voice")]),_c('el-radio-button',{attrs:{"label":"Chatbot"}},[_vm._v("Chatbot")]),_c('el-radio-button',{attrs:{"label":"Biometrics"}},[_vm._v("Biometrics Enrol")])],1),_c('div',{staticStyle:{"display":"flex","padding-top":"24px","padding-bottom":"24px","font-size":"18px","font-weight":"800","color":"#555555"}},[_c('div',[_vm._v(" "+_vm._s(_vm.alertCandidate === "IVA" ? "Usage Alerts" : "Balance Alerts")+" ")]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.usageAlertTooltip,"placement":"right"}},[_c('div',{staticStyle:{"padding-left":"10px"}},[_c('i',{staticClass:"el-icon-info"})])])],1),(_vm.alertCandidate === 'IVA')?_c('div',{style:(_vm.alertSettings.iva_threshold.length === 0
            ? 'margin-left:0px; padding-bottom:24px; margin-right:20px'
            : 'margin-left:20px; padding-bottom:24px; margin-right:20px')},[(!_vm.alertSettings.iva_threshold.length)?_c('div',{staticStyle:{"font-size":"16px","font-weight":"400","color":"#444444"}},[_vm._v(" "+_vm._s(_vm.__("You have no alerts set up."))+" ")]):_vm._e(),_vm._l((_vm.alertSettings.iva_threshold),function(item,index){return _c('el-form-item',{key:index,attrs:{"prop":"iva_threshold","label":_vm.__('Alert ') + (index + 1)}},[_c('el-row',{attrs:{"gutter":5}},[_c('el-col',{attrs:{"span":21}},[_c('el-slider',{ref:"ivaSlider",refInFor:true,attrs:{"min":0,"max":100,"marks":_vm.ivaSliderMarks,"format-tooltip":_vm.formatIvaSliderTooltip},on:{"change":function($event){return _vm.handleIvaSliderChange(index)}},model:{value:(_vm.alertSettings.iva_threshold[index]),callback:function ($$v) {_vm.$set(_vm.alertSettings.iva_threshold, index, $$v)},expression:"alertSettings.iva_threshold[index]"}})],1),_c('el-col',{staticStyle:{"padding-left":"18px"},attrs:{"span":2}},[_c('el-input',{attrs:{"value":_vm.alertSettings.iva_threshold[index] + '%',"readonly":""}})],1),(_vm.alertSettings.iva_threshold.length === index + 1)?_c('el-col',{attrs:{"span":1}},[_c('el-button',{ref:"deleteAlertButton",refInFor:true,staticStyle:{"border":"0","padding-top":"10px"},on:{"click":_vm.handleDeleteAlertLevelClick}},[_c('div',[_c('i',{staticClass:"el-icon-close",staticStyle:{"font-size":"20px"}})])])],1):_vm._e()],1)],1)})],2):_vm._e(),(_vm.alertCandidate === 'Chatbot')?_c('div',{staticStyle:{"padding-bottom":"24px"}},[(!_vm.alertSettings.chat_threshold.length)?_c('div',{staticStyle:{"font-size":"16px","font-weight":"400","color":"#444444"}},[_vm._v(" "+_vm._s(_vm.__("You have no alerts set up."))+" ")]):_vm._e(),_vm._l((_vm.alertSettings.chat_threshold),function(item,index){return _c('el-form-item',{key:index,style:(index + 1 === _vm.alertSettings.chat_threshold.length
              ? 'margin-bottom: 0px'
              : {}),attrs:{"label":_vm.__('Alert ') + (index + 1)}},[_c('el-row',{staticStyle:{"display":"inline-flex"}},[_c('el-col',[_c('el-input-number',{attrs:{"min":1},model:{value:(_vm.alertSettings.chat_threshold[index]),callback:function ($$v) {_vm.$set(_vm.alertSettings.chat_threshold, index, $$v)},expression:"alertSettings.chat_threshold[index]"}})],1),(_vm.alertSettings.chat_threshold.length === index + 1)?_c('el-col',[_c('el-button',{ref:"deleteAlertButton",refInFor:true,staticStyle:{"border":"0","padding-top":"10px"},on:{"click":_vm.handleDeleteAlertLevelClick}},[_c('div',[_c('i',{staticClass:"el-icon-close",staticStyle:{"font-size":"20px"}})])])],1):_vm._e()],1)],1)})],2):_vm._e(),(_vm.alertCandidate === 'Biometrics')?_c('div',{staticStyle:{"padding-bottom":"24px"}},[(!_vm.alertSettings.biometrics_threshold.length)?_c('div',{staticStyle:{"font-size":"16px","font-weight":"400","color":"#444444"}},[_vm._v(" "+_vm._s(_vm.__("You have no alerts set up."))+" ")]):_vm._e(),_vm._l((_vm.alertSettings.biometrics_threshold),function(item,index){return _c('el-form-item',{key:index,style:(index + 1 === _vm.alertSettings.biometrics_threshold.length
              ? 'margin-bottom: 0px'
              : {}),attrs:{"label":'Alert ' + (index + 1)}},[_c('el-row',{staticStyle:{"display":"inline-flex"}},[_c('el-col',[_c('el-input-number',{attrs:{"min":1},model:{value:(_vm.alertSettings.biometrics_threshold[index]),callback:function ($$v) {_vm.$set(_vm.alertSettings.biometrics_threshold, index, $$v)},expression:"alertSettings.biometrics_threshold[index]"}})],1),(_vm.alertSettings.biometrics_threshold.length === index + 1)?_c('el-col',[_c('el-button',{ref:"deleteAlertButton",refInFor:true,staticStyle:{"border":"0","padding-top":"10px"},on:{"click":_vm.handleDeleteAlertLevelClick}},[_c('div',[_c('i',{staticClass:"el-icon-close",staticStyle:{"font-size":"20px"}})])])],1):_vm._e()],1)],1)})],2):_vm._e(),_c('el-form-item',[(_vm.showAddAlertButton)?_c('el-button',{ref:"addAlertButton",on:{"click":_vm.handleAddAlertLevelButtonClick}},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"padding-right":"4px"}},[_c('i',{staticClass:"el-icon-plus"})]),_c('div',[_vm._v(_vm._s(_vm.showAddAlertButtonText))])])]):_vm._e()],1),_c('el-form-item',{staticStyle:{"display":"flex","justify-content":"end"}},[_c('div',[(!_vm.contentNotChanged)?_c('el-button',{ref:"cancelButton",attrs:{"disabled":_vm.contentNotChanged || !this.validatePermission(),"type":"primary"},on:{"click":_vm.handleCancel}},[_vm._v(" Cancel ")]):_vm._e(),(!_vm.contentNotChanged)?_c('el-button',{ref:"saveButton",staticClass:"submitBtn",attrs:{"disabled":_vm.contentNotChanged || !this.validatePermission(),"type":"primary"},on:{"click":_vm.submitForm}},[_vm._v(" Save ")]):_vm._e()],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }