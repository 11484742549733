<template>
  <el-form
    id="mySpProfileForm"
    ref="mySpProfileForm"
    :rules="rules"
    :model="spProfileSettings"
    label-position="top"
    size="large"
    hide-required-asterisk
    v-loading="isLoading || loading"
  >
    <el-row type="flex">
      <el-col :span="12">
        <el-form-item :label="__('Service Provider ID')">
          <div>{{ spProfileSettings.sp_id }}</div>
        </el-form-item>
        <el-form-item :label="__('Name')" prop="sp_name">
          <el-input v-model="spProfileSettings.sp_name"></el-input>
        </el-form-item>

        <!-- // need to verify if the subdomain is unique -->
        <el-form-item :label="__('Subdomain')" prop="subdomain">
          <el-input
            v-model="spProfileSettings.subdomain"
            v-loading="isCheckingSubdomain"
          >
            <template slot="append">.{{ appLocationHost }}</template>
          </el-input>
        </el-form-item>

        <el-form-item :label="__('Note')" prop="note">
          <el-input v-model="spProfileSettings.note"></el-input>
        </el-form-item>

        <el-form-item label="" prop="display_date_format">
          <display-date-format-dropdown
            v-model="spProfileSettings.display_date_format"
            @display-date-format-changed="displayDateFormatChanged"
          />
        </el-form-item>

        <el-form-item :label="__('Timezone')" prop="default_timezone">
          <timezone-dropdown
            :default_timezone="spProfileSettings.default_timezone"
            @timezone-changed="handleTimezoneChange"
            :key="timezoneKey"
          />
        </el-form-item>

        <el-form-item :label="__('Logo')" prop="logopath">
          <logo-uploader
            :folder="folder"
            :file-url="spProfileSettings.logo_url"
            @on-success="handleSuccess"
            @on-error="handleError"
          />
        </el-form-item>

        <el-form-item>
          <el-button
            @click="submitForm"
            class="submitBtn"
            :disabled="!canWrite()"
          >
            {{ __("Update") }}
          </el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import _ from "lodash";
import TimezoneDropdown from "@/components/TimezoneDropdown.vue";
import LogoUploader from "@/components/uploaders/types/AvatarUploader";
import { checkUniqueSubdomain } from "@/api/serviceproviders";
import { alphaNumericDashesOnly } from "@/utils/regex";
import DisplayDateFormatDropdown from "@/components/DisplayDateFormatDropdown";

export default {
  name: "MySpProfileManageIndex",
  components: {
    LogoUploader,
    TimezoneDropdown,
    DisplayDateFormatDropdown
  },
  data() {
    let validateSubdomain = async (rule, value, callback) => {
      try {
        if (value) {
          this.isCheckingSubdomain = true;
          if (!alphaNumericDashesOnly.test(value)) {
            callback(
              new Error(
                "Subdomain must contain only alphabets, letters and dashes."
              )
            );
          }

          const res = await checkUniqueSubdomain({
            subdomain: value,
            sp_id: this.selectedServiceProviderId
          });
          if (res.data.found) {
            callback(new Error("Subdomain already exists"));
          } else if (res.status !== 200) {
            callback(new Error(res.data.message));
          } else {
            callback();
          }
        }
      } catch (e) {
        callback(new Error(e.message));
      } finally {
        this.isCheckingSubdomain = false;
      }
    };

    return {
      folder: "logo",
      timezoneKey: 0,
      selectedSp: 0,
      spProfileSettings: {
        sp_name: "",
        subdomain: "",
        note: "",
        default_timezone: "",
        display_date_format: "",
        logo_url: ""
      },
      isCheckingSubdomain: false,
      rules: {
        sp_name: [
          {
            required: true,
            trigger: "blur",
            message: __("Service Provider Name is required")
          }
        ],
        subdomain: [
          {
            trigger: "blur",
            validator: validateSubdomain
          }
        ]
      },
      isLoading: false
    };
  },

  computed: {
    ...mapState("serviceproviders", {
      loading: state => state.loading,
      serviceProviderData: state => state.serviceProviderData
    }),
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId
    }),
    ...mapGetters("app", {
      userRoleHasSystemRoleOrScope: "userRoleHasSystemRoleOrScope",
      appLocationHost: "appLocationHost"
    })
  },

  methods: {
    ...mapActions("serviceproviders", {
      getServiceProviderData: "getServiceProviderData",
      updateServiceProvider: "updateServiceProvider"
    }),

    handleSuccess(res) {
      console.log(res.url);
      this.spProfileSettings.logo_url = res.url;
      this.spProfileSettings.logopath = res.path;
      this.uploadedFilePath = res.path;
    },

    handleError(err) {
      console.log(err);
    },

    handleTimezoneChange(timezone) {
      this.$set(this.spProfileSettings, "default_timezone", timezone);
    },

    submitForm() {
      this.isLoading = true;
      this.$refs.mySpProfileForm.validate(valid => {
        if (valid) {
          this.updateServiceProvider(this.spProfileSettings)
            .then(() => {
              this.isLoading = false;
              this.$message({
                type: "success",
                message: __("Service Provider updated successfully.")
              });
            })
            .catch(err => {
              this.isLoading = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
      this.isLoading = false;
    },

    async initializeContents() {
      if (!_.isEmpty(this.serviceProviderData)) {
        let selectedSp = _.find(this.serviceProviderData, {
          sp_id: this.selectedServiceProviderId
        });
        this.spProfileSettings = _.cloneDeep(selectedSp);
        this.timezoneKey++;
      } else {
        this.resetForm();
      }
    },

    resetForm() {
      this.$set(this.spProfileSettings, "sp_name", "");
      this.$set(this.spProfileSettings, "subdomain", "");
      this.$set(this.spProfileSettings, "note", "");
      this.$set(this.spProfileSettings, "default_timezone", "");
      this.$set(this.spProfileSettings, "display_date_format", "");
      this.$set(this.spProfileSettings, "logo_url", "");
      this.timezoneKey++;
    },

    /**
     * handle display date format changed
     * @param displayDateFormat
     */
    displayDateFormatChanged(displayDateFormat) {
      this.spProfileSettings.display_date_format = displayDateFormat;
    }
  },

  watch: {
    selectedServiceProviderId: "initializeContents"
  },

  async mounted() {
    this.isLoading = true;

    await this.getServiceProviderData(this.selectedServiceProviderId);
    if (!_.isEmpty(this.serviceProviderData)) {
      this.spProfileSettings = _.cloneDeep(this.serviceProviderData);
      this.timezoneKey++;
    } else {
      this.resetForm();
    }
    this.isLoading = false;
  },

  beforeDestroy: function() {
    this.$echo.leave("sp_settings." + this.$auth.user().user_id);
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";
@import "~@/styles/node_common.scss";
@import "~@/styles/button.scss";
@import "~@/styles/form_common.scss";

.el-tag.el-tag--danger {
  background-color: #feedee !important;
  border-color: #fedadd !important;
  color: #f84856 !important;
}

.el-tag.el-tag--success {
  background-color: #eef8ed !important;
  border-color: #dcf1da !important;
  color: #51b848 !important;
}
</style>
