<template>
  <el-form
    id="alertSettingsForm"
    ref="alertSettingsForm"
    :model="alertSettings"
    :rules="rules"
    label-position="top"
    size="large"
    hide-required-asterisk
    v-loading="loading"
  >
    <el-row type="flex">
      <el-col :span="24">
        <!-- Email -->
        <el-form-item :label="__('Email Addresses')" prop="email_receiver_list">
          <el-input
            type="textarea"
            v-model="alertSettings.email_receiver_list"
            :placeholder="__('Enter Email Addresses')"
            maxlength="500"
            show-word-limit
          ></el-input>
          <div style="font-size: small">
            <!-- eslint-disable-next-line -->
            {{ __("Alerts will be sent to the entered email addresses and repeat daily. You can enter multiple comma-separated email addresses.") }}
          </div>
        </el-form-item>
        <!-- Alert Candidate -->
        <el-radio-group
          v-model="alertCandidate"
          @change="handleAlertCandidateChange"
          size="medium"
        >
          <el-radio-button label="IVA">Voice</el-radio-button>
          <el-radio-button label="Chatbot">Chatbot</el-radio-button>
          <el-radio-button label="Biometrics">Biometrics Enrol</el-radio-button>
        </el-radio-group>

        <!-- Usage Title -->
        <div
          style="display: flex; padding-top:24px; padding-bottom:24px; font-size: 18px; font-weight: 800; color: #555555"
        >
          <div>
            {{ alertCandidate === "IVA" ? "Usage Alerts" : "Balance Alerts" }}
          </div>
          <el-tooltip
            class="item"
            effect="dark"
            :content="usageAlertTooltip"
            placement="right"
          >
            <div style="padding-left: 10px">
              <i class="el-icon-info"></i>
            </div>
          </el-tooltip>
        </div>

        <!-- IVA Section -->
        <div
          :style="
            alertSettings.iva_threshold.length === 0
              ? 'margin-left:0px; padding-bottom:24px; margin-right:20px'
              : 'margin-left:20px; padding-bottom:24px; margin-right:20px'
          "
          v-if="alertCandidate === 'IVA'"
        >
          <div
            style="font-size: 16px; font-weight: 400; color: #444444"
            v-if="!alertSettings.iva_threshold.length"
          >
            {{ __("You have no alerts set up.") }}
          </div>
          <el-form-item
            prop="iva_threshold"
            v-for="(item, index) in alertSettings.iva_threshold"
            :key="index"
            :label="__('Alert ') + (index + 1)"
          >
            <el-row :gutter="5"
              ><el-col :span="21">
                <el-slider
                  ref="ivaSlider"
                  v-model="alertSettings.iva_threshold[index]"
                  :min="0"
                  :max="100"
                  :marks="ivaSliderMarks"
                  :format-tooltip="formatIvaSliderTooltip"
                  @change="handleIvaSliderChange(index)"
                ></el-slider>
              </el-col>
              <el-col style="padding-left: 18px;" :span="2">
                <el-input
                  :value="alertSettings.iva_threshold[index] + '%'"
                  readonly
                >
                </el-input>
              </el-col>
              <el-col
                :span="1"
                v-if="alertSettings.iva_threshold.length === index + 1"
              >
                <el-button
                  style="border:0; padding-top:10px"
                  @click="handleDeleteAlertLevelClick"
                  ref="deleteAlertButton"
                >
                  <div>
                    <i style="font-size: 20px" class="el-icon-close"></i>
                  </div>
                </el-button> </el-col
            ></el-row>
          </el-form-item>
        </div>

        <!-- Chatbot Section -->
        <div style="padding-bottom:24px" v-if="alertCandidate === 'Chatbot'">
          <div
            style="font-size: 16px; font-weight: 400; color: #444444"
            v-if="!alertSettings.chat_threshold.length"
          >
            {{ __("You have no alerts set up.") }}
          </div>
          <el-form-item
            :style="
              index + 1 === alertSettings.chat_threshold.length
                ? 'margin-bottom: 0px'
                : {}
            "
            v-for="(item, index) in alertSettings.chat_threshold"
            :key="index"
            :label="__('Alert ') + (index + 1)"
          >
            <el-row style="display: inline-flex;">
              <el-col>
                <el-input-number
                  v-model="alertSettings.chat_threshold[index]"
                  :min="1"
                ></el-input-number>
              </el-col>
              <el-col v-if="alertSettings.chat_threshold.length === index + 1">
                <el-button
                  style="border:0; padding-top:10px"
                  @click="handleDeleteAlertLevelClick"
                  ref="deleteAlertButton"
                >
                  <div>
                    <i style="font-size: 20px" class="el-icon-close"></i>
                  </div>
                </el-button>
              </el-col>
            </el-row>
          </el-form-item>
        </div>

        <!-- Biometrics Enrol Section -->
        <div style="padding-bottom:24px" v-if="alertCandidate === 'Biometrics'">
          <div
            style="font-size: 16px; font-weight: 400; color: #444444"
            v-if="!alertSettings.biometrics_threshold.length"
          >
            {{ __("You have no alerts set up.") }}
          </div>
          <el-form-item
            :style="
              index + 1 === alertSettings.biometrics_threshold.length
                ? 'margin-bottom: 0px'
                : {}
            "
            v-for="(item, index) in alertSettings.biometrics_threshold"
            :key="index"
            :label="'Alert ' + (index + 1)"
          >
            <el-row style="display: inline-flex;">
              <el-col>
                <el-input-number
                  v-model="alertSettings.biometrics_threshold[index]"
                  :min="1"
                ></el-input-number>
              </el-col>
              <el-col
                v-if="alertSettings.biometrics_threshold.length === index + 1"
              >
                <el-button
                  style="border:0; padding-top:10px"
                  @click="handleDeleteAlertLevelClick"
                  ref="deleteAlertButton"
                >
                  <div>
                    <i style="font-size: 20px" class="el-icon-close"></i>
                  </div>
                </el-button>
              </el-col>
            </el-row>
          </el-form-item>
        </div>

        <!-- Add alert button -->
        <el-form-item>
          <el-button
            v-if="showAddAlertButton"
            @click="handleAddAlertLevelButtonClick"
            ref="addAlertButton"
          >
            <div style="display: flex">
              <div style="padding-right: 4px"><i class="el-icon-plus"></i></div>
              <div>{{ showAddAlertButtonText }}</div>
            </div>
          </el-button>
        </el-form-item>

        <!-- Cancel and Save button-->
        <el-form-item style="display:flex; justify-content: end">
          <div>
            <el-button
              :disabled="contentNotChanged || !this.validatePermission()"
              v-if="!contentNotChanged"
              type="primary"
              @click="handleCancel"
              ref="cancelButton"
            >
              Cancel
            </el-button>
            <el-button
              :disabled="contentNotChanged || !this.validatePermission()"
              v-if="!contentNotChanged"
              type="primary"
              @click="submitForm"
              class="submitBtn"
              ref="saveButton"
            >
              Save
            </el-button>
          </div>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { validEmail } from "@/utils/validate";
import _ from "lodash";

export default {
  name: "SpAlertSettingsManageIndex",
  data() {
    let validateEmails = async (rule, value, callback) => {
      // Allow empty email (STUD-5593)
      // https://five9inc.atlassian.net/browse/STUD-5593
      if (value === "") {
        return callback();
      }
      value.split(",").forEach(email => {
        email = email.trim();
        if (!validEmail(email)) {
          callback(new Error(__("Please enter valid email")));
        }
      });
      callback();
    };
    return {
      alertSettings: {
        alert_setting_id: -1,
        email_receiver_list: "",
        iva_threshold: [],
        chat_threshold: [],
        biometrics_threshold: []
      },
      rules: {
        email_receiver_list: [
          {
            required: true,
            validator: validateEmails,
            trigger: "blur"
          }
        ]
      },
      alertCandidate: "IVA",
      ivaSliderMarks: {
        0: "0",
        10: "10",
        20: "20",
        30: "30",
        40: "40",
        50: "50",
        60: "60",
        70: "70",
        80: "80",
        90: "90",
        100: "100"
      }
    };
  },

  computed: {
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId,
      selectedServiceProviderId: state => state.selectedServiceProviderId
    }),
    ...mapState("alerts", {
      spAlertSettingsState: state => state.spAlertSettingsState,
      loading: state => state.loading
    }),
    ...mapGetters("app", {
      formattedDateTime: "formattedDateTime"
    }),
    getIconSvg() {
      return require("@/assets/icons/icon-info.svg");
    },

    usageAlertTooltip() {
      if (
        this.alertCandidate === "Chatbot" ||
        this.alertCandidate === "Biometrics"
      ) {
        return __(
          "Alerts will be triggered when the remaining usage of any of the service provider's accounts drops below the predefined thresholds."
        );
      } else if (this.alertCandidate === "IVA") {
        return __(
          "Alerts will be triggered when the percentage utilization of any of the service provider's accounts exceeds the predefined thresholds."
        );
      } else {
        return "";
      }
    },

    contentNotChanged() {
      if (this.spAlertSettingsState.length === 0) {
        if (this.alertSettings.email_receiver_list !== "") {
          return false;
        }
        if (this.alertSettings.iva_threshold.length !== 0) {
          return false;
        }
        if (this.alertSettings.chat_threshold.length !== 0) {
          return false;
        }
        if (this.alertSettings.biometrics_threshold.length !== 0) {
          return false;
        }
        return true;
      }
      return _.isEqual(this.spAlertSettingsState, this.alertSettings);
    },
    showAddAlertButton() {
      if (this.alertCandidate === "Chatbot") {
        return this.alertSettings.chat_threshold.length < 3;
      } else if (this.alertCandidate === "Biometrics") {
        return this.alertSettings.biometrics_threshold.length < 3;
      } else if (this.alertCandidate === "IVA") {
        return this.alertSettings.iva_threshold.length < 3;
      } else {
        return false;
      }
    },

    showAddAlertButtonText() {
      if (this.alertCandidate === "Chatbot") {
        return this.alertSettings.chat_threshold.length === 0
          ? __("Add Alert")
          : __("Add Another Alert");
      } else if (this.alertCandidate === "Biometrics") {
        return this.alertSettings.biometrics_threshold.length === 0
          ? __("Add Alert")
          : __("Add Another Alert");
      } else if (this.alertCandidate === "IVA") {
        return this.alertSettings.iva_threshold.length === 0
          ? __("Add Alert")
          : __("Add Another Alert");
      } else {
        return false;
      }
    }
  },
  methods: {
    ...mapActions("alerts", {
      getSpAlertSettings: "getSpAlertSettings",
      updateOrCreateSpAlertSettings: "updateOrCreateSpAlertSettings"
    }),

    handleAlertCandidateChange() {
      if (this.spAlertSettingsState.length !== 0) {
        this.alertSettings = _.cloneDeep(this.spAlertSettingsState);
      }
    },
    handleCancel() {
      if (this.spAlertSettingsState.length === 0) {
        this.reloadForm();
      } else {
        this.alertSettings = _.cloneDeep(this.spAlertSettingsState);
      }
    },

    resetForm() {
      this.$set(this.alertSettings, "alert_setting_id", -1);
      this.$set(this.alertSettings, "email_receiver_list", "");
      this.$set(this.alertSettings, "iva_threshold", []);
      this.$set(this.alertSettings, "chat_threshold", []);
      this.$set(this.alertSettings, "biometrics_threshold", []);
    },

    validatePermission() {
      return this.can("manage.sp-profile.write");
    },
    checkIfAlertValuesAreValid() {
      if (!this.isAscendingUniqueArray(this.alertSettings.iva_threshold)) {
        this.$message({
          message: __("Unable to update IVA alerts"),
          type: "error"
        });
        return false;
      }
      if (!this.isDescendingUniqueArray(this.alertSettings.chat_threshold)) {
        this.$message({
          message: __("Unable to update Chatbot alerts"),
          type: "error"
        });
        return false;
      }
      if (
        !this.isDescendingUniqueArray(this.alertSettings.biometrics_threshold)
      ) {
        this.$message({
          message: "Unable to update Biometric Enrolment alerts",
          type: "error"
        });
        return false;
      }
      return true;
    },
    submitForm() {
      if (this.checkIfAlertValuesAreValid()) {
        if (this.validatePermission()) {
          this.$refs.alertSettingsForm.validate(valid => {
            if (valid) {
              const process = this.updateOrCreateSpAlertSettings;
              this.processRequest(process);
            }
          });
        } else {
          this.$message({
            message: __("You do not have permission to perform this action."),
            type: "error"
          });
        }
      }
    },

    processRequest(process) {
      process(this.alertSettings)
        .then(() => {
          if (!_.isEmpty(this.spAlertSettingsState)) {
            this.alertSettings = _.cloneDeep(this.spAlertSettingsState);
          }
          this.$message({
            type: "success",
            message: __("Alert settings updated successfully.")
          });
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: err.message
          });
        });
    },

    async reloadForm() {
      await this.getSpAlertSettings();
      if (!_.isEmpty(this.spAlertSettingsState)) {
        this.alertSettings = _.cloneDeep(this.spAlertSettingsState);
      } else {
        this.resetForm();
      }
    },
    isAscendingUniqueArray(arr) {
      const sortedArr = _.sortBy(arr);
      const uniqueArr = _.uniq(arr);
      return _.isEqual(arr, sortedArr) && _.isEqual(arr, uniqueArr);
    },
    isDescendingUniqueArray(arr) {
      const sortedArr = _.sortBy(arr).reverse();
      const uniqueArr = _.uniq(arr);
      return _.isEqual(arr, sortedArr) && _.isEqual(arr, uniqueArr);
    },
    handleAddAlertLevelButtonClick() {
      //add new
      if (this.alertCandidate === "Chatbot") {
        if (
          this.alertSettings.chat_threshold[
            this.alertSettings.chat_threshold.length - 1
          ] === 1
        ) {
          this.$message({
            message: __("Cannot add another level of alerts"),
            type: "error"
          });
        } else {
          this.alertSettings.chat_threshold.push(
            this.alertSettings.chat_threshold.length === 0
              ? 1000
              : this.alertSettings.chat_threshold[
                  this.alertSettings.chat_threshold.length - 1
                ] - 1
          );
        }
      } else if (this.alertCandidate === "Biometrics") {
        if (
          this.alertSettings.biometrics_threshold[
            this.alertSettings.biometrics_threshold.length - 1
          ] === 1
        ) {
          this.$message({
            message: __("Cannot add another level of alert"),
            type: "error"
          });
        } else {
          this.alertSettings.biometrics_threshold.push(
            this.alertSettings.biometrics_threshold.length === 0
              ? 50
              : this.alertSettings.biometrics_threshold[
                  this.alertSettings.biometrics_threshold.length - 1
                ] - 1
          );
        }
      } else if (this.alertCandidate === "IVA") {
        if (
          this.alertSettings.iva_threshold[
            this.alertSettings.iva_threshold.length - 1
          ] === 100
        ) {
          this.$message({
            message: __("Cannot add another level of alert"),
            type: "error"
          });
        } else {
          this.alertSettings.iva_threshold.push(
            this.alertSettings.iva_threshold.length === 0
              ? 50
              : this.alertSettings.iva_threshold[
                  this.alertSettings.iva_threshold.length - 1
                ] + 1
          );
        }
      }
    },
    handleDeleteAlertLevelClick() {
      if (this.alertCandidate === "Chatbot") {
        this.alertSettings.chat_threshold.pop();
      } else if (this.alertCandidate === "Biometrics") {
        this.alertSettings.biometrics_threshold.pop();
      } else if (this.alertCandidate === "IVA") {
        this.alertSettings.iva_threshold.pop();
      }
    },
    formatIvaSliderTooltip(val) {
      return val + "%";
    }
  },
  watch: {
    selectedAccountId: {
      immediate: true,
      handler: function(newVal, oldVal) {
        if (oldVal === undefined) {
          return;
        }
        this.reloadForm();
      }
    },
    selectedServiceProviderId: {
      immediate: true,
      handler: function(newVal, oldVal) {
        if (oldVal === undefined) {
          return;
        }
        this.reloadForm();
      }
    }
  },
  async mounted() {
    await this.getSpAlertSettings();
    if (!_.isEmpty(this.spAlertSettingsState)) {
      this.alertSettings = _.cloneDeep(this.spAlertSettingsState);
    } else {
      this.resetForm();
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";
@import "~@/styles/node_common.scss";
@import "~@/styles/button.scss";
@import "~@/styles/form_common.scss";
</style>
